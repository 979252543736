<template>
  <GumSkeleton v-if="initialLoading" />
  <AntForm
    v-else
    :label-col="horizontalLayout ? { span: 6 } : undefined"
    :wrapper-col="horizontalLayout ? { span: 18 } : undefined"
    :labelWrap="true"
    @submit="emit('submit')"
    class="w-full"
    :layout="layout || 'horizontal'"
  >
    <GumAlert v-if="!inline && baseErrorMessages?.length" type="error" class="mb-4">
      <div v-for="(error, index) in baseErrorMessages" :key="index">{{ error }}</div>
    </GumAlert>

    <slot />
  </AntForm>
</template>

<script setup lang="ts">
import AntForm from 'ant-design-vue/es/form'
import { computed, provide, ref, watchEffect } from 'vue'
import { FORM_GLOBAL_STATE_KEY, type FormGlobalState } from './global-state'
import GumAlert from '../GumAlert.vue'
import { type Errors } from '@/types/errors'
import { GumSkeleton } from '..'

export type GumFormProps = {
  errors?: Errors
  initialLoading?: boolean
  disabled?: boolean
  inline?: boolean
  layout?: 'horizontal' | 'vertical'
}

const props = withDefaults(defineProps<GumFormProps>(), {
  initialLoading: false,
  layout: 'horizontal'
})
const emit = defineEmits<{
  (e: 'submit'): void
}>()

const horizontalLayout = computed(() => props.layout === 'horizontal')

// Global state that can be accessed via all child components
const globalState = ref<FormGlobalState>({
  errors: props.errors || [],
  disabled: props.disabled || false,
  layout: props.layout
})

watchEffect(() => {
  globalState.value.errors = props.errors || []
  globalState.value.disabled = props.disabled
  globalState.value.layout = props.layout
})

provide(FORM_GLOBAL_STATE_KEY, globalState)

const baseErrorMessages = computed(() =>
  props.errors?.filter((err) => err.path === 'base').flatMap((err) => err.messages)
)
</script>
