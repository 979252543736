<template>
  <AntFormItem
    :label="inline ? undefined : label || (horizontalLayout ? ' ' : undefined)"
    hasFeedback
    :validateStatus="errors ? 'error' : undefined"
    :help="errors"
    :required="required"
    :labelCol="horizontalLayout ? { span: inline ? 0 : 8 } : undefined"
    :wrapperCol="horizontalLayout ? { span: inline ? 24 : 16 } : undefined"
    :colon="!!label && label.trim().length > 0"
    class="gum-form-item"
  >
    <template v-slot:extra v-if="help">
      <span class="whitespace-pre-line">{{ help }}</span>
    </template>
    <slot />
  </AntFormItem>
</template>

<script setup lang="ts">
import { FormItem as AntFormItem } from 'ant-design-vue/es/form'
import { computed } from 'vue'
import { useFormState } from './global-state'

const formState = useFormState()

interface Props {
  label?: string
  required?: boolean
  path?: string | string[]
  help?: string
  inline?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  required: false,
  inline: false
})

const horizontalLayout = computed(() => formState?.value.layout === 'horizontal')

// Errors
const errors = computed(() => {
  if (!props.path || !formState?.value.errors) {
    return null
  }

  const targetPaths = Array.isArray(props.path) ? props.path : [props.path]

  const fieldsWithErrors = formState?.value.errors.filter((err) => targetPaths.includes(err.path))
  const errorString = fieldsWithErrors.map((field) => field.messages.join('. ')).join('. ')

  return errorString || null
})
</script>

<style lang="less">
.gum-form-item {
  .ant-form-item-extra {
    @apply text-xs;
  }
}
</style>
